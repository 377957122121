import { CloseAppBar } from '@/components/AppBar';

import styles from './PolicyPage.module.css';

const ServicePolicyPageContent = (
  <>
    <section>
      <h2>제1조(목적)</h2>
      <p>
        이 약관은 숙명여자대학교 커뮤니티 "스노로즈"(이하 "스노로즈")에서
        제공하는 인터넷 관련 서비스(이하 "서비스")를 이용함에 있어 "스노로즈"와
        이용자의 권리.의무 및 책임사항을 규정함을 목적으로 합니다.
      </p>
    </section>

    <section>
      <h2>제2조(정의)</h2>
      <p>
        ① "이용자"란 "스노로즈"에 접속하여 이 약관에 따라 "스노로즈"가 제공하는
        서비스를 받는 회원 및 비회원을 말합니다.
        <br />② "회원"이란 "스노로즈"에 회원등록을 한 자로서, 계속적으로
        "스노로즈"가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
        <br />
        ③ "아이디(ID)"란 회원의 식별과 서비스 이용을 위하여 회원이 문자와 숫자의
        조합으로 설정한 고유의 체계를 말합니다.
        <br />④ "비밀번호"란 아이디(ID)로 식별되는 회원의 본인 여부를 검증하기
        위하여 회원이 설정하여 "스노로즈"에 등록한 고유의 문자와 숫자의 조합을
        말합니다.
      </p>
    </section>

    <section>
      <h2>제3조(약관 등의 명시와 설명 및 개정)</h2>
      <p>
        ① "스노로즈"는 이 약관의 내용과 개인정보보호방침, 전자우편주소,
        개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 "스노로즈"의 초기
        서비스화면(하단)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을
        통하여 볼 수 있도록 할 수 있습니다.
        <br />
        ② "스노로즈"는 「약관의 규제에 관한 법률」, 「전자문서 및
        전자거래기본법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등
        관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        <br />
        ③ "스노로즈"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
        현행약관과 함께 "스노로즈"의 초기화면에 그 적용일자 7일 이전부터
        적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을
        변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이
        경우 "스노로즈"는 개정 전 내용과 개정 후 내용을 명확하게 비교하여
        이용자가 알기 쉽도록 표시합니다.
        <br />
        ④ "스노로즈"가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에
        체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정
        전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가
        개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의
        공지기간 내에 "스노로즈"에 송신하여 "스노로즈"의 동의를 받은 경우에는
        개정약관 조항이 적용됩니다.
        <br />⑤ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
        약관의 규제 등에 관한 법률 및 관계법령 또는 상관례에 따릅니다.
      </p>
    </section>

    <section>
      <h2>제4조(서비스의 제공 및 변경)</h2>
      <p>① "스노로즈"는 다음과 같은 서비스를 제공합니다.</p>
      <ol>
        <li>숙명여자대학교 커뮤니티 서비스</li>
        <li>본교 강의 정보 서비스</li>
        <li>광고 및 제휴 서비스</li>
        <li>
          기타 "스노로즈"가 자체 개발하거나 다른 회사와의 협력 계약 등을 통해
          회원들에게 제공할 일체의 서비스
        </li>
      </ol>
      <p>
        ② "스노로즈"가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 기술적
        사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한
        주소로 즉시 통지합니다.
      </p>
    </section>

    <section>
      <h2>제5조(서비스의 중단)</h2>
      <p>
        ① "스노로즈"는 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의
        두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수
        있습니다.
        <br />
        ② 제1항에 의한 서비스 중단의 경우에는 "스노로즈"는 제8조 제2항에서 정한
        방법으로 이용자에게 통지합니다. 다만, "스노로즈"가 통제할 수 없는 사유로
        인한 서비스의 중단(시스템 관리자의 고의, 과실이 없는 디스크 장애,
        시스템다운 등)으로 인하여 사전 통지가 불가능한 경우에는 그러하지
        아니합니다.
        <br />③ "스노로즈"는 제1항의 사유로 서비스의 제공이 일시적으로
        중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단,
        "스노로즈"가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
        아니합니다.
      </p>
    </section>

    <section>
      <h2>제6조(회원가입)</h2>
      <p>
        ① 이용자는 "스노로즈"가 정한 가입 양식에 따라 회원정보를 기입한 후 이
        약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
        <br />② "스노로즈"는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중
        다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
      </p>
      <ol>
        <li>
          가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한
          적이 있는 경우, 다만 제7조 제3항에 의한 회원자격 상실 후 10년이 경과한
          자로서 "스노로즈"의 회원재가입 승낙을 얻은 경우에는 예외로 합니다.
        </li>
        <li>등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
        <li>
          기타 회원으로 등록하는 것이 "스노로즈"의 기술상 현저히 지장이 있다고
          판단되는 경우
        </li>
      </ol>
      <p>
        ③ 회원가입계약의 성립 시기는 "스노로즈"의 승낙이 회원에게 도달한
        시점으로 합니다.
        <br />④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간
        이내에 "스노로즈"에 대하여 회원정보 수정 등의 방법으로 그 변경사항을
        알려야 합니다.
      </p>
    </section>

    <section>
      <h2>제7조(회원 탈퇴 및 자격 상실 등)</h2>
      <p>
        ① 회원은 "스노로즈"에 언제든지 탈퇴를 요청할 수 있으며 "스노로즈"는 즉시
        회원탈퇴를 처리합니다.
        <br />② 회원이 다음 각 호의 사유에 해당하는 경우, "스노로즈"는
        회원자격을 제한 및 정지시킬 수 있습니다.
      </p>
      <ol>
        <li>가입 신청 시에 허위 내용을 등록한 경우</li>
        <li>
          다른 사람의 "스노로즈" 이용을 방해하거나 그 정보를 도용하는 등
          전자상거래 질서를 위협하는 경우
        </li>
        <li>
          "스노로즈"를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는
          행위를 하는 경우
        </li>
        <li>숙명여자대학교 구성원이 아닌 경우</li>
      </ol>
      <p>
        ③ "스노로즈"가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이
        경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의
        기간을 정하여 소명할 기회를 부여합니다.
      </p>
    </section>

    <section>
      <h2>제8조(회원에 대한 통지)</h2>
      <p>
        ① "스노로즈"가 회원에 대한 통지를 하는 경우, 회원이 "스노로즈"와 미리
        약정하여 지정한 전자우편 주소로 할 수 있습니다.
        <br />② "스노로즈"는 불특정다수 회원에 대한 통지의 경우 1주일이상
        "스노로즈" 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다.
      </p>
    </section>

    <section>
      <h2>제9조(개인정보보호)</h2>
      <p>
        ① "스노로즈"는 관련법령이 정하는 바에 따라서 회원 등록정보를 포함한
        회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보보호에
        관해서는 관련법령 및 "스노로즈"가 별도로 정하는 '개인정보보호방침’에
        정한 바에 의합니다.
        <br />② "스노로즈"는 회원의 개인정보를 기록 및 관리할 의무가 있으며
        임의로 회원의 등록정보를 포함한 회원의 개인정보를 열람하지 않습니다.
      </p>
    </section>

    <section>
      <h2>제10조("스노로즈"의 의무)</h2>
      <p>
        ① "스노로즈"는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지
        않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를
        제공하는데 최선을 다하여야 합니다.  <br />
        ② "스노로즈"는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록
        이용자의 개인정보 보호를 위한 보안 시스템을 갖추어야 합니다.  <br />
        ③ "스노로즈"는 이용자가 원하지 않는 영리목적의 광고성 전자우편을
        발송하지 않습니다.  <br />
        ④ "스노로즈"는 서비스 제공과 관련해서 알고 있는 회원의 신상 정보를
        본인의 승낙 없이 제3자에게 누설하거나 배포하지 않습니다. 단,
        전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에
        대한 수사상의 목적이 있거나 또는 기타 관계법령에서 정한 절차에 의한
        요청이 있을 경우에는 협조할 수 있습니다.
        <br />⑤ "스노로즈"는 이용자가 서비스를 이용함에 있어 "스노로즈"의 고의
        또는 중대한 과실로 인하여 입은 손해를 배상할 책임을 부담합니다.
      </p>
    </section>

    <section>
      <h2>제11조(회원의 ID 및 비밀번호에 대한 의무)</h2>
      <p>
        ① 제9조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게
        있습니다.
        <br />
        ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.  
        <br />③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고
        있음을 인지한 경우에는 바로 “스노로즈”에 통보하고 "스노로즈"의 안내가
        있는 경우에는 그에 따라야 합니다.
      </p>
    </section>

    <section>
      <h2>제12조(이용자의 의무)</h2>
      <p>
        ① 이용자는 다음 행위를 하여서는 안 됩니다.
        <br />
        <ol>
          <li>신청 또는 변경시 허위 내용의 등록</li>
          <li>타인의 정보 도용</li>
          <li>"스노로즈"에 게시된 정보의 변경</li>
          <li>
            "스노로즈"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신
            또는 게시
          </li>
          <li>"스노로즈" 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
          <li>
            "스노로즈" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
          </li>
          <li>
            외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를
            몰에 공개 또는 게시하는 행위
          </li>
          <li>기타 "스노로즈"가 제공하는 서비스에 정한 약관을 위반하는 행위</li>
        </ol>
        ② 제1항에 해당하는 행위를 한 이용자가 있을 경우 "스노로즈"가 본 약관
        제7조 제2, 3항에서 정한 바에 따라 이용자의 회원자격을 적절한 방법으로
        제한 및 정지, 상실시킬 수 있습니다.   ③ 이용자는 그 귀책사유로 인하여
        "스노로즈"나 다른 이용자가 입은 손해를 배상할 책임이 있습니다.
      </p>
    </section>

    <section>
      <h2>제13조(저작권의 귀속 및 이용제한)</h2>
      <p>
        ① "스노로즈"가 작성한 저작물에 대한 저작권 기타 지적재산권은
        "스노로즈"에 귀속합니다.  <br />
        ② 이용자는 "스노로즈"를 이용함으로써 얻은 정보 중 "스노로즈"에게
        지적재산권이 귀속된 정보를 "스노로즈"의 사전 승낙 없이 복제, 송신, 출판,
        배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게
        하여서는 안됩니다.  <br />③ "스노로즈"는 약정에 따라 이용자에게 귀속된
        저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
      </p>
    </section>

    <section>
      <h2>제14조(분쟁해결)</h2>
      <p>
        ① "스노로즈"는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그
        피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.    
        <br />② "스노로즈"는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로
        그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그
        사유와 처리일정을 즉시 통보해 드립니다.
      </p>
    </section>

    <section>
      <h2>제15조(재판권 및 준거법)</h2>
      <p>
        ① "스노로즈"와 이용자 간에 발생한 서비스 이용에 관한 분쟁에 관하여는
        대한민국 법을 적용합니다.  <br />② "스노로즈"와 이용자 간에 발생한
        서비스 이용에 관한 분쟁으로 인한 소송은 제소 당시의 이용자의 주소에
        의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로
        합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국
        거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
      </p>
    </section>

    <section>
      <ul>
        <li>공고일자 : 2024년 10월 12일</li>
        <li>시행일자 : 2024년 10월 12일</li>
      </ul>
    </section>
  </>
);

export default function ServicePolicyPage() {
  return (
    <main className={styles.policyPage}>
      <CloseAppBar alignRight={true} />
      <section className={styles.contentWrapper}>
        <h1 className={styles.title}>서비스 이용 약관</h1>
        <article className={styles.content}>{ServicePolicyPageContent}</article>
      </section>
    </main>
  );
}
