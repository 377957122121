import { CloseAppBar } from '@/components/AppBar';
import styles from './PolicyPage.module.css';

const privacyPolicyContent = (
  <>
    <p>
      스노로즈는 회원의 사생활 및 개인정보를 적극적으로 보호하여 정보화
      사회에서의 통신의 자유를 보장하고자 개인정보보호법, 정보통신망 이용촉진 및
      정보보호 등에 관한 법률에 따라 아래와 같이 개인정보보호정책을 명시하여
      실천하고 있습니다.
    </p>
    <p>
      스노로즈 개인정보보호정책은 관련 법률 및 정부 지침의 변경과 스노로즈의
      내부 정책 변화에 따라 변경될 수 있습니다. 스노로즈의 개인정보보호정책이
      변경될 경우 변경사항은 스노로즈 홈페이지에 게시됩니다.
    </p>
    <p>
      본 방침은 2007년 8월 15일부터 시행되었으며, 현재 내용은 2024년 10월 12일에
      업데이트 되었습니다.
    </p>
    <br />
    <section>
      <h2>1. 개인정보의 수집 목적</h2>
      <p>
        스노로즈는 개인정보를 다음의 목적을 위해 수집, 처리합니다. 처리한
        개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며, 이용 목적이
        변경될 시에는 사전 동의를 구할 예정입니다.
      </p>
      <p>
        <u>수집목적</u>
      </p>
      <ul>
        <li>
          회원제 서비스 제공, 개인식별, 이용약관 위반 회원에 대한 이용제한 조치,
          서비스의 원활한 운영에 지장을 미치는 행위 및 서비스 부정이용 행위
          제재, 가입의사 확인, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인,
          분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달,
          회원탈퇴 의사의 확인.
        </li>
        <li>
          양질의 서비스 제공 및 회원 개개인의 기호와 필요에 따른 맞춤화된
          서비스를 제공해드리기 위함.
        </li>
        <li>
          또한 기타 통계처리, 학술연구, 시장조사를 위하여 필요한 경우에는 특정한
          개인을 식별할 수 없는 가공된 통계 자료의 형태로만 정보가 제공됩니다.
        </li>
      </ul>
    </section>

    <section>
      <h2>2. 개인정보의 수집 방법, 항목 및 범위</h2>
      <p>
        스노로즈는 서비스의 이용을 위하여 회원 가입 시에 회원 정보를 기입하도록
        합니다.
      </p>
      <ul>
        <li>수집항목: 이름, 전화번호, 이메일, 학번 및 학적정보 </li>
      </ul>
      <p>
        이외 기타 추가적인 정보가 필요한 특정 서비스의 이용 시 추가 정보의
        제공을 요청할 수 있습니다. 이 경우에도 기입하신 정보는 해당 서비스의
        이용 및 사전에 밝힌 목적 이외에는 이용하지 않습니다.
      </p>
    </section>

    <section>
      <h2>3. 개인정보의 처리 및 보유 기간</h2>
      <p>
        스노로즈 회원의 개인정보는 원칙적으로 개인정보의 수집 및
        이용목적(사이트의 이용을 위한 정보 수집)이 달성되면 지체 없이
        파기합니다. 단, 다음의 정보에 대해서는 아래의 보존 이유를 위해 명시기간
        동안 보존합니다.
      </p>
      <p>
        <u>부정이용기록</u> (부정가입, 경고 및 징계(강등)기록 등의 부정 서비스
        이용기록)
        <br />
        <ul>
          <li>
            보존 항목: 회원가입 시의 기본 필수 정보 및 경고 및 징계 사유 기록
          </li>
          <li>보존 기간: 영구강등 시 강등 시점으로부터 10년간</li>
          <li>보존 이유: 재가입 방지를 위해 기록 보존</li>
        </ul>
      </p>
      <p>
        <u>로그인 기록</u> (정상적 서비스 이용기록)
        <ul>
          <li>보존 항목: 사이트 방문 기록</li>
          <li>보존 기간: 3개월</li>
          <li>
            보존 이유: 고객의 불만/민원 처리, 소송 등 분쟁이 진행 중이거나
            예상되는 경우를 위하여
          </li>
        </ul>
      </p>
    </section>

    <section>
      <h2>4. 개인정보의 공유 및 제공</h2>
      <p>
        스노로즈는 원칙적으로 회원의 개인정보를 어느 누구와도 공유하지 않습니다.
      </p>
      <p>
        다만 회원님이 공개를 동의한 경우 또는 스노로즈 이용자 약관을 위배하거나
        타인에게 피해를 주는 행위 등으로 법적인 조치가 요구되고 적법한 절차에
        의해 관련 정부 기관의 요구가 있을 경우에는 예외로 합니다. 스노로즈는
        보다 나은 서비스의 제공을 위해 회원의 개인정보를 제휴업체와 일부 공유할
        수 있으나, 이 경우에도 정보수집 또는 정보제공 이전에 제휴업체 정보,
        요청되는 정보의 종류, 정보의 보호 및 관리 방안에 대하여 알리고 동의를
        구하는 절차를 거치며, 회원이 동의하지 않는 경우에는 수집 및 공유하지
        않습니다.
      </p>
    </section>

    <section>
      <h2>5. 자신의 개인정보 열람, 수정 및 삭제</h2>
      <p>
        스노로즈의 회원은 언제든지 자신의 개인정보를 열람하거나 수정할 수
        있으며, 회원탈퇴를 통해서 개인정보의 삭제를 요청할 수 있습니다.
        개인정보의 열람, 수정은 스노로즈 마이페이지에서 하실 수 있으며, 특수
        수정사항(학번, 이름)은 운영진에게 요청 시 수정이 가능합니다. 삭제는
        회원탈퇴 요청을 통해 하실 수 있습니다.
      </p>
    </section>

    <section>
      <h2>6. 개인정보의 보유 및 이용 기간</h2>
      <p>
        스노로즈의 회원으로서 스노로즈 서비스를 제공받는 동안 개인정보는
        스노로즈가 계속 보유하고 서비스의 제공을 위해서 이용합니다. 회원
        등록해지 및 또는 스노로즈 이용자 약관에 의거한 아이디 삭제의 경우에
        수집된 개인정보는 재생할 수 없도록 하드디스크에서 완전히 삭제되며 어떠한
        용도로도 열람, 이용할 수 없게 처리됩니다.
      </p>
      <p>
        <u>탈퇴 회원의 개인정보 이용</u>
      </p>
      <ul>
        <li>
          스노로즈는 회원정보를 회원이 탈퇴하는 시점으로부터 1년까지 보유할 수
          있습니다.
          <ul>
            <li>
              회원식별정보
              <ul>
                <li>보존 항목: 이름, 학번, 이메일</li>
                <li>보존 기간: 탈퇴 후 1년</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          스노로즈가 보관하고 있는 탈퇴 회원의 정보는 회원의 가입 이력 관리와
          지적재산권 관리, 개인정보 보호를 위해서만 활용할 수 있습니다.{' '}
        </li>
        <li>
          단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 스노로즈는 아래와
          같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
          <ul>
            <li>이용자 불만 또는 분쟁 처리에 관한 기록 : 5년</li>
            <li>계약 또는 청약철회 등에 관한 기록 : 5년</li>
            <li>대금결제 및 재화 등의 공급에 관한 기록 : 5년</li>
          </ul>
        </li>
      </ul>
    </section>

    <section>
      <h2>7. 강제탈퇴된 유저의 재가입 불허</h2>
      <p>
        스노로즈의 서비스 이용중 사용자가 준수해야 하는 사항의 위반으로
        강제탈퇴된 경우 재가입이 불허될 수 있습니다.
      </p>
    </section>

    <section>
      <h2>8. 스노로즈의 개인정보 보호</h2>
      <p>
        스노로즈의 개인정보는 기본적으로 회원의 아이디와 비밀번호에 의하여
        보호됩니다.
      </p>
      <p>
        개인정보의 열람 및 수정은 해당 아이디와 비밀번호를 알고 있는 본인만이
        가능합니다.
      </p>
      <p>
        본인이 자신의 아이디와 비밀번호를 유출하였다면 이에 대해서 스노로즈는
        책임을 지지 않습니다. 해당 아이디와 비밀번호는 절대 본인만이 사용하시고,
        비밀번호를 자주 바꿔주시는 것이 좋습니다. 또한 비밀번호는 영문자, 숫자,
        특수문자를 혼합하여 타인이 유추하기 어려운 번호를 사용하는 것이
        좋습니다. 스노로즈 서비스의 이용을 마친 후에는 항상 로그아웃을 해주시고
        웹브라우저를 닫아주시는 것이 좋습니다. 특히, 다른 사람과 컴퓨터를
        공유하거나, 공공장소에서 이용하는 경우에 개인정보의 보안을 위해서는 이와
        같은 절차가 더욱 필요합니다.
      </p>
      <p>
        스노로즈는 해킹 등에 의한 정보 유출의 방지를 위한 보안 및 재난 등의 정보
        훼손 방지를 위한 데이터 백업 등의 기술적 관리에도 최선을 다하고
        있습니다. 또한 스노로즈는 이용자 본인의 비밀번호 요청 등에 의해
        개인정보를 다룰 때 가능한 최선의 방법으로 본인임을 확인하고 안전하게
        정보가 처리될 수 있도록 최선을 다합니다. 그리고 개인정보 관리 권한을
        가진 관리자를 최소화하고 지속적인 교육을 통하여 개인정보보호정책을
        준수하고 있습니다.
      </p>
      <p>
        스노로즈의 개인정보는 기본적으로 본인이 설정한 아이디와 비밀번호에 의해
        보호되며, 개인정보의 열람 및 수정은 원칙적으로 본인만 가능합니다. 단,
        관리 및 제재가 필요한 경우 운영진의 열람이 허용됩니다.
      </p>
    </section>

    <section>
      <h2>9. 개인정보 보호(관리) 책임자</h2>
      <p>
        스노로즈는 개인정보 처리에 관한 업무를 총괄하여 책임지고, 개인정보
        처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
        개인정보 보호책임자를 지정하고 있습니다.
      </p>
      <ul>
        <li>개인정보 보호책임자: 스노로즈 운영진 회장 (약 1년마다 변경됨)</li>
        <li>연락처: smsnorose@gmail.com</li>
      </ul>
    </section>

    <section>
      <h2>10. 개인정보 처리방침 변경 및 고지</h2>
      <p>
        이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른
        변경내용의 추가,삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터
        공지사항을 통하여 고지할 것입니다.
      </p>
    </section>

    <section>
      <h2>11. 기타</h2>
      <p>
        채팅, 게시판, 이메일 등을 통해 자신이 자발적으로 제공하는 개인정보는
        다른 사람들에 의하여 본인의 의도와는 다르게 이용될 수 있다는 사실을
        염두에 두어야 합니다.
      </p>
      <p>
        타인에게 공개되어 있는 장소에서는 자신의 개인정보를 함부로 남겨서는
        안됩니다.
      </p>
      <p>
        또한 PC방이나 기타 공공장소 등 주변에 사람들이 많은 곳에서는 서비스를
        이용하고 있는 동안 다른 사람들이 자신의 컴퓨터 화면을 볼 수도 있다는
        사실을 염두에 두고 개인정보가 노출되지 않도록 주의하여야 합니다.
      </p>
    </section>
  </>
);

export default function PrivacyPolicyPage() {
  return (
    <main className={styles.policyPage}>
      <CloseAppBar alignRight={true} />
      <section className={styles.contentWrapper}>
        <h1 className={styles.title}>개인정보 처리방침</h1>
        <article className={styles.content}>{privacyPolicyContent}</article>
      </section>
    </main>
  );
}
